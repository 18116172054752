import React, { useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { gsap, Power2 } from "gsap";

import StyledLink from "components/StyledLink";
import Heading from "components/Heading";
import { textTransition } from "components/Animations";

import "./_image-row.scss";

const ImageRow = ({
  subheading,
  text,
  image,
  buttonText,
  buttonUrl,
  isReversed,
}) => {
  const trigger = useRef(null);
  const container = useRef(null);
  const heading = useRef(null);
  const textRef = useRef(null);
  const button = useRef(null);
  const imageOverlay = useRef(null);

  useEffect(() => {
    const tl = new gsap.timeline();
    const { duration, from, to } = textTransition;

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          tl.fromTo(heading.current, duration, from, to)
            .fromTo(textRef.current, duration, from, to, "-=0.7")
            .fromTo(button.current, duration, from, to, "-=0.7")
            .set(container.current, { visibility: "visible" }, "-=1")
            .to(
              imageOverlay.current,
              1.4,
              {
                width: "0%",
                ease: Power2.easeInOut,
              },
              "-=1"
            );
          observer.unobserve(trigger.current);
        }
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0.3,
      }
    );
    if (trigger.current) {
      observer.observe(trigger.current);
    }
  }, []);

  return (
    <div
      ref={trigger}
      className={isReversed ? "image-row image-row--reversed" : "image-row"}
    >
      <div className="image-row__text-wrapper">
        <div ref={heading} className="image-row__heading">
          {subheading ? (
            <Heading headingLevel="h1" headingStyle="h1">
              {subheading}
            </Heading>
          ) : null}
        </div>
        <p ref={textRef} className="image-row__text">
          {text}
        </p>
        <div ref={button}>
          <StyledLink text={buttonText} url={buttonUrl} />
        </div>
      </div>
      <div
        ref={container}
        className={
          isReversed
            ? "image-row__image-wrapper image-row__image-wrapper--reversed"
            : "image-row__image-wrapper"
        }
      >
        <div className="image-row__inner-wrapper">
          {image}
          <div ref={imageOverlay} className="image-row__image-overlay"></div>
        </div>
      </div>
    </div>
  );
};

ImageRow.propTypes = {
  subheading: PropTypes.string,
  text: PropTypes.string.isRequired,
  image: PropTypes.node.isRequired,
  buttonUrl: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  isReversed: PropTypes.bool,
};

export default ImageRow;
