import React from "react";
import BackgroundImage from "gatsby-background-image";
import PropTypes from "prop-types";

import StyledLink from "components/StyledLink";

import "./_banner.scss";

const Banner = ({
  heading,
  text,
  buttonText,
  buttonUrl,
  imageData,
  isCentered,
}) => {
  return (
    <BackgroundImage Tag="div" className="banner" fluid={imageData}>
      <div
        className={
          isCentered
            ? "banner__content banner__content--centered"
            : "banner__content"
        }
      >
        {heading &&
          (isCentered ? (
            <h1 className="banner__heading banner__heading--main">{heading}</h1>
          ) : (
            <h2 className="banner__heading">{heading}</h2>
          ))}
        {text && <p className="banner__text">{text}</p>}
        {buttonUrl && (
          <StyledLink text={buttonText} url={buttonUrl} isWhite={true} />
        )}
      </div>
    </BackgroundImage>
  );
};

Banner.propTypes = {
  imageData: PropTypes.object.isRequired,
  heading: PropTypes.string,
  isCentered: PropTypes.bool,
  text: PropTypes.string,
  buttonUrl: PropTypes.string,
  buttonText: PropTypes.string,
};

export default Banner;
