import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

import { navigation } from "src/data";
import Header from "components/Header";
import Main from "components/Main";
import ImageRow from "components/ImageRow";
import Button from "components/Button";
import Row from "components/Row";
import TextBlock from "components/TextBlock";
import Banner from "components/Banner";
import Footer from "components/Footer";
import SEO from "components/SEO";

import "styles/styles.scss";

export const fluidImage = graphql`
  fragment fluidImage on File {
    id
    childImageSharp {
      fluid(quality: 100) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
`;

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query Images {
      hero: file(relativePath: { eq: "sss.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      logo: file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 70, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      uniq: file(relativePath: { eq: "galerija/kudrenai(19).jpg" }) {
        ...fluidImage
      }
      uniq2: file(relativePath: { eq: "main3.jpg" }) {
        ...fluidImage
      }
    }
  `);

  return (
    <>
      <SEO />
      <Header
        items={navigation}
        logo={data.logo.childImageSharp.fluid}
        logoAlt="Sodyba Kudrenai Logo"
        navPosition="right"
      />
      <Banner imageData={data.hero.childImageSharp.fluid} />
      <Main>
        <ImageRow
          subheading="Unikali vieta šventėms ir renginiams"
          text="Stebuklinga tyla, svečius supanti gamta, tobulas skonis, komfortas – vos 15 km nuo Kauno centro. Sodyba „Kudrėnai” įsikūrusi vienoje gražiausių Kauno regiono vietų – Nevėžio kraštovaizdžio draustinyje. Nuo šalia Raudondvario esančios Nemuno ir Nevėžio santakos važiuojant Nevėžio upės vaga aukštyn link Babtų, rasite jaukią, ramią vietą, skirtą asmeninėms ir įmonių šventėms bei konferencijoms."
          buttonText="Apie sodybą"
          buttonUrl="/sodyba"
          image={
            <Img
              fluid={data.uniq.childImageSharp.fluid}
              alt="sodyba kauno rajone"
            />
          }
        />
        <ImageRow
          isReversed={true}
          text="Sodyba „Kudrėnai” svečiams siūlo komfortiškai įrengtus kambarius, modernią salę, tinklinio aikštelę, dvi pirtis su baseinu, nepriekaištingą virtuvę su patraukliu meniu, bei išpuoselėtą, kviečiančią atsipūsti ir pailsėti aplinką."
          buttonText="Apžiūrėkite mūsų galeriją"
          buttonUrl="/galerija"
          image={
            <Img
              fluid={data.uniq2.childImageSharp.fluid}
              alt="sodyba kaune Kudrenai"
            />
          }
        />
        <Button url="/kontaktai">Rezervuoti vizitą</Button>
        <Row>
          <TextBlock
            title="Jaukios vestuvės sodyboje"
            text="Atidžiai atkreipdami dėmesį net į smulkiausias detales, mes degame troškimu padaryti Jūsų vestuves lengvas, džiugias ir įsimintinas. Sodyba „Kudrėnai“ yra ypatinga vieta švęsti geriausią Jūsų gyvenimo dieną."
            buttonText="Vestuvės"
            buttonUrl="/vestuves"
          />
          <TextBlock
            title="Profesionaliai patiektas maistas"
            text="Nesvarbu ar mūsų sodyboje švęsite pašėlusį gimtadienį, nuostabias vestuves, turėsite darbo pobūvį ar net varginančią konferenciją – mūsų profesionalios virėjos pateiktas maistas pradžiugins ir pamalonins net ir pačius išrankiausius gurmanus."
            buttonText="Maitinimas"
            buttonUrl="/maitinimas"
          />
        </Row>
      </Main>
      <Footer />
    </>
  );
};

export default IndexPage;
